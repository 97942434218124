.home-modal {
    &.ui {
        &.modal {
            &>.header {
                background-color: var(--y-grey3);
                i{  
                    font-size: 24px;
                    &:before{
                        color: var(--y-green1);
                        text-shadow: 0 0 15px var(--y-green1);
                    }
                }
                .ymodal-headertitle {
                    font-family: PoppinsSemiBold;
                    margin-left: 10px;
                    color: white;
                }
            }
            &>.content {
                font-family: PoppinsRegular;
                color: var(--y-grey6);
            }
        }
    }
}
.y-modal-bnt {
    margin-left: 5px;
}