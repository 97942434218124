#root{
    width: 100%;
    height: 100%;
}
body, html{
    width: 100%;
    height: 100%;
}
:root {
    --item-per-row-guide : 4;
    --item-per-row : 3;
    --item-per-row-preview: 4;
    --challenge-margin-left: 113px;
    --nav-height: 50px;
    --extra-nav-height : 112px;
    --subnav-height: 80px;
    --sidebar-width: 60px;
    --sidebar-width-practice: 80px;
    --sidebar-left-practice: 80px;
    --sidebar-left: 60px;
    --white: white;
    --grey1: #4F4F4F;
    --grey2: #4F4F4F;
    --grey3: #828282;
    --grey4: #BDBDBD;
    --grey5: #E0E0E0;
    --grey6: #F2F2F2;
    --pink: #FB4081;
    --blue1: #242F56;
    --blue2: #4269B0;
    --blue3: #2D9CDB;
    --blue4: #50E3C2;
    --blue5: #33BDC2;
    --blue6: #4A90E2;
    --blue7: #16559F;
    --blue8: #022651;
    --blue9: #4063DF;
    --blue10: #394ABC;
    --orange1: #E2684B;
    --red1: #DB0000;
    --red2: #EF4D4D;
    --red3: #FFA0A0;
    --red4: #FF325D;
    --yellow: #FFB53F;
    --y-grey1:#99B0BC;
    --y-grey2:#8CA3B0;
    --y-grey3:#31425A;
    --y-grey4:#E9ECED;
    --y-grey5:#393939;
    --y-grey6:#9E9E9E;
    --y-grey7:#31425A;
    --y-grey8:#F1F6F8;
    --y-grey9:#838E9C;
    --y-grey10:#EDF1F2;
    --y-grey11:#505865;
    --y-grey12:#CFDEE2;
    --y-grey13:#B6B6B6;
    --y-grey14:#C4C4C4;
    --y-grey15:#615F5F;
    --y-grey16:#AFADAD;
    --y-grey17:#E1E8E6;
    --y-grey18:#E8EDEC;
    --y-green1:#1FD5B9;
    --y-green2:#06f9d4;
    --y-blue1:#1A367C;
    --y-blue2:#31435b;
    --y-red1: #DB4A38;
    --y-color-shadow: #1C2C43;
    --primary-colors-blue-4-30-2: #D7E8FF;
    --primary-colors-blue-4: #82D4F3;
    //variables after make an user guide
    //brands colors
    --brand-primary: #30425A;
    --brand-primary-light:#98a3af;
    --brand-primary-extra-light: #CCD1D7;
    --brand-secondary:#1fd5b9;
    --brand-secondary-rgb: 31, 213, 185;
    --brand-secondary-dark:#1bb89f;
    //neutral-colors 
    --brand-black: #000000;
    --brand-white: #FFFFFF;
    --dark-grey: #3D3D3D;
    --dark-grey-2: #686C71;
    --dark-grey-3: #84898F;
    --dark-grey-4:#eaecef;
    --light-grey-1: #B2BAC4;
    --light-grey-2: #F8F8F8;
    //utility and overlay colors
    --red-color:#FF6767;
    --brand-error: #E20D0D;
    --brand-positive: #2DCC70;
    --brand-alarm: #FFCF00;
    --blue-link: #3598D4;
    --border-color: #CBCBCB;
    --font-primary: PoppinsBold;
    --font-secondary: PoppinsRegular;
    @media only screen and (max-width:920px) {
      --nav-height: 65px;
      --extra-nav-height: 80px;
    }
    @media only screen and (max-width: 920px) {
      --sidebar-left: 0;
      --sidebar-left-practice: 0;
   }
}